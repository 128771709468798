import React, { lazy, Suspense, useContext } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { withErrorBoundary } from 'react-error-boundary';
import { useAuth } from 'react-oidc-context';
import { FeatureFlags } from 'contexts/Features.context';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import NotificationsPanelView from 'views/NotificationsPanelView/NotificationsPanelView';

const Pipeline = lazy(() => import('./pages/Pipeline/Pipeline'));
const CustomerRequests = lazy(() => import('./pages/CustomerRequests/CustomerRequests'));
const Vehicle = lazy(() => import('./pages/VehicleDetails/VehicleDetails'));
const Bookmarks = lazy(() => import('./pages/Bookmarks/Bookmarks'));
const BookmarksGroupShare = lazy(() => import('./pages/BookmarksGroupShare/BookmarksGroupShare'));
const ErrorPage = lazy(() => import('./pages/ErrorPage/ErrorPage'));

const Router = () => {
  const auth = useAuth();
  const { features } = useContext(FeatureFlags);

  switch (auth.activeNavigator) {
    case 'signinSilent':
    case 'signinRedirect':
      return <div>Redirecting to login...</div>;
    case 'signoutRedirect': {
      return <div>Redirecting to login...</div>;
    }
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingSpinner fullScreen />}>
        <Routes>
          <Route path="/" element={<Pipeline />} />
          <Route path="/vehicle/:modelYear/:urn" element={<Vehicle />} />
          <Route path="/customer-requests/:profilePathName?" element={<CustomerRequests />} />
          {features?.bookmarksEnabled && <Route path="/bookmarks" element={<Bookmarks />} />}
          {<Route path="/bookmarks/:groupShareId" element={<BookmarksGroupShare />} />}
          <Route path="/authorization" element={<ErrorPage type={'authorization'} />} />
          <Route path="*" element={<ErrorPage type={'404'} />} />
        </Routes>
      </Suspense>
      <NotificationsPanelView />
    </BrowserRouter>
  );
};

export default withErrorBoundary(Router, {
  fallback: <ErrorPage type={'problems'} hideNavBar />,
});
