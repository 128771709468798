import React from 'react';
import {
  Cancel as CancelIcon,
  CheckCircle as CheckIcon,
  DoNotDisturbOn as DoNotDisturbOnIcon,
  Timer as TimerIcon,
  Warning as WarningIcon,
} from '@mui/icons-material';
import { chipColorMap } from './colorMappers';
import theme from '../theme';

export const countTypes = [
  'schedule_count',
  'risk_count',
  'early_count',
  'delay_count',
  'unavailable_count',
  'delivered_count',
];

const statusMap = {
  countTypes,
  propMap: {
    schedule_count: {
      backgroundColor: theme.palette.etaStatus.onSchedule.gradient,
      color: theme.palette.success.main,
      iconId: 'CheckCircleIcon',
      label: 'On Schedule',
      type: 'success',
    },
    risk_count: {
      backgroundColor: theme.palette.etaStatus.atRisk.gradient,
      color: theme.palette.warning.main,
      iconId: 'WarningIcon',
      label: 'At Risk',
      type: 'warning',
    },
    early_count: {
      backgroundColor: theme.palette.etaStatus.early.gradient,
      color: theme.palette.purple[50],
      iconId: 'TimerIcon',
      label: 'Early',
      type: 'early',
    },
    delay_count: {
      backgroundColor: theme.palette.etaStatus.delayed.gradient,
      color: theme.palette.error.main,
      iconId: 'CancelIcon',
      label: 'Delayed',
      type: 'error',
    },
    unavailable_count: {
      backgroundColor: theme.palette.etaStatus.etaUnavailable.gradient,
      color: theme.palette.text.disabled,
      iconId: 'DoNotDisturbOnIcon',
      label: 'ETA Unavailable',
      type: 'unavailable',
    },
    delivered_count: {
      backgroundColor: theme.palette.etaStatus.delivered.gradient,
      color: chipColorMap.currentEvent,
      label: 'Delivered',
    },
  },
};

// allow us to dynamically get icons and pass style props
const getCancelIcon = ({ fontSize = '10', ...props }) => (
  <CancelIcon color="error" fontSize={`${fontSize}`} sx={{ ...props }} />
);
const getCheckIcon = ({ fontSize = '10', ...props }) => (
  <CheckIcon color="success" fontSize={`${fontSize}`} sx={{ ...props }} />
);
const getWarningIcon = ({ fontSize = '10', ...props }) => (
  <WarningIcon color="warning" fontSize={`${fontSize}`} sx={{ ...props }} />
);
const getDoNotDisturbIcon = ({ fontSize = '10', ...props }) => (
  <DoNotDisturbOnIcon color="disabled" fontSize={`${fontSize}`} sx={{ ...props }} />
);
const getTimerIcon = ({ fontSize = '10', ...props }) => (
  <TimerIcon
    fontSize={`${fontSize}`}
    sx={{ color: statusMap.propMap.early_count.color, ...props }}
  />
);

// get icon by count type
export const countGetIcon = {
  schedule_count: getCheckIcon,
  risk_count: getWarningIcon,
  delay_count: getCancelIcon,
  unavailable_count: getDoNotDisturbIcon,
  early_count: getTimerIcon,
};

// get icon by status type
export const typeGetIcon = {
  success: getCheckIcon,
  warning: getWarningIcon,
  early: getTimerIcon,
  error: getCancelIcon,
  unavailable: getDoNotDisturbIcon,
};

export const typeGetLabel = {
  success: 'Vehicle is anticipated to arrive on-time',
  warning: 'Vehicle may arrive after original delivery time',
  early: 'Vehicle is arriving ahead of schedule',
  error: 'Vehicle delivery is behind schedule',
  unavailable: 'ETA is currently not available',
};

export const labels = statusMap.countTypes.map((countType) => statusMap.propMap[countType].label);
export const propMap = statusMap.propMap;
export const statusArray = countTypes.map((countType) => propMap[countType]);
export const typesArray = statusArray.map((status) => status.type);
export const colorArray = statusArray.map((status) => status.color);

// sum array of objects, e.g. [{schedule_count: 50}, {schedule_count: 13}] -> [63]
export const reduceByCountType = (data, countType) =>
  data.reduce((a, v) => a + (v[countType] || 0), 0);

export const tableStatusMap = {
  'on schedule': 'schedule_count',
  'at risk': 'risk_count',
  early: 'early_count',
  delayed: 'delay_count',
  'eta unavailable': 'unavailable_count',
  delivered: 'delivered_count',
};

export const inventoryCategoryBgColorMap = {
  'on schedule': chipColorMap.inventoryCategoryOnSchedule,
  'at risk': chipColorMap.inventoryCategoryAtRisk,
  early: chipColorMap.inventoryCategoryEarly,
  delayed: chipColorMap.inventoryCategoryDelayed,
  'eta unavailable': chipColorMap.inventoryCategoryETAUnavailable,
  delivered: chipColorMap.inventoryCategoryDelivered,
};

export const getStatusChipColor = (status) => {
  const key = tableStatusMap[status?.toLowerCase()];
  return propMap[key]?.color;
};

export const getStatusBackgroundColor = (status) => {
  const key = tableStatusMap[status.toLowerCase()];
  return propMap[key]?.backgroundColor;
};

export const getInventoryCategoryChipColor = (status) =>
  inventoryCategoryBgColorMap[status.toLowerCase()];

export const getRowChipColor = (value) => {
  switch (value) {
    case 'Not Available':
      return 'text.disabled';
    case 'Current':
    case 'Installed':
    case 'Inline Installed':
    case 'Offline Installed':
    case 'ROUTED':
    case 'FROZEN':
      return chipColorMap.etaStatusOnSchedule;
    default:
      return chipColorMap.etaStatusAtRisk;
  }
};

export const getRowChipBgColor = (value) => {
  switch (value) {
    case 'Not Available':
      return 'secondary.80';
    case 'Current':
    case 'Installed':
    case 'Inline Installed':
    case 'Offline Installed':
    case 'ROUTED':
    case 'FROZEN':
      return chipColorMap.allocationCompletedBackground;
    default:
      return chipColorMap.allocationUnassignedBackground;
  }
};
