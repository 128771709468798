import React, { createContext, useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty, isEqual } from 'lodash';
import { cleanObject } from '../utils/general';
import useSessionStorage from '../hooks/useSessionStorage';

export const UserProfile = createContext({});

const cleanInlineFilterObjects = ({ rawSelectedProfile, newFilters }) => {
  let rawFilter = get(rawSelectedProfile, 'value');
  let newFilter = get(newFilters, 'value');

  if (get(newFilter, 'inline')) {
    const cleanInlineNewFilter = cleanObject(get(newFilter, 'inline'));
    newFilter = {
      ...newFilter,
      inline: isEmpty(cleanInlineNewFilter) ? null : cleanInlineNewFilter,
    };
  }

  if (get(rawFilter, 'inline')) {
    const cleanInlineRawFilter = cleanObject(get(rawFilter, 'inline'));
    rawFilter = {
      ...rawFilter,
      inline: isEmpty(cleanInlineRawFilter) ? null : cleanInlineRawFilter,
    };
  }

  return { rawFilter, newFilter };
};

const handleLegacyValues = ({ selectedUserProfile, setSelectedUserProfile }) => {
  let isChanged = false;
  if (isEmpty(selectedUserProfile)) {
    return;
  }
  const selectedUserProfileCopy = { ...selectedUserProfile };
  if (get(selectedUserProfileCopy, 'value.fullPageFiltersRequest')) {
    delete selectedUserProfileCopy.value.fullPageFiltersRequest;
    isChanged = true;
  }

  if (get(selectedUserProfileCopy, 'value.fullPageFiltersStored')) {
    delete selectedUserProfileCopy.value.fullPageFiltersStored;
    isChanged = true;
  }
  if (isChanged) {
    setSelectedUserProfile(selectedUserProfileCopy);
  }
};

const UserProfileProvider = ({ children }) => {
  const [selectedUserProfile, setSelectedUserProfile, isValueSet] =
    useSessionStorage('userProfile');

  const [userProfiles, setUserProfiles] = useState(null);
  const [isFilterChanged, setIsFilterChanged] = useState(false);
  const [isProfilesLoading, setIsProfilesLoading] = useState(true);
  const [isTemporaryProfile, setIsTemporaryProfile] = useState(false);

  useEffect(() => {
    if (isValueSet && !isProfilesLoading) {
      checkIsProfileChanged(selectedUserProfile);
    }
  }, [isProfilesLoading]);

  useEffect(
    () => handleLegacyValues({ selectedUserProfile, setSelectedUserProfile }),
    [selectedUserProfile],
  );

  const setFilerChange = (isChanged) => {
    setIsFilterChanged(isChanged);
  };

  const checkIsProfileChanged = (newFilters) => {
    const rawSelectedProfile = userProfiles?.find(
      (item) => item.key === get(selectedUserProfile, 'key'),
    );

    const { rawFilter, newFilter } = cleanInlineFilterObjects({ rawSelectedProfile, newFilters });

    if (!isEmpty(rawFilter) && !isEmpty(newFilter)) {
      const isSameFilter = isEqual(rawFilter, newFilter);
      setIsFilterChanged(!isSameFilter);
    } else {
      setIsFilterChanged(false);
    }
  };

  const setFilters = ({ filterType, filters }) => {
    if (isProfilesLoading) {
      return;
    }
    const newFilters = {
      ...(selectedUserProfile || {}),
      value: {
        ...get(selectedUserProfile, 'value', {}),
        [filterType]: filters,
      },
    };
    checkIsProfileChanged(newFilters);
    setSelectedUserProfile(newFilters);
  };

  const setMultipleFilters = (filters = {}) => {
    if (isProfilesLoading) {
      return;
    }
    const newFilters = {
      ...(selectedUserProfile || {}),
      value: {
        ...get(selectedUserProfile, 'value', {}),
        ...filters,
      },
    };
    checkIsProfileChanged(newFilters);
    setSelectedUserProfile(newFilters);
  };

  const dispatchProfiles = useCallback((profiles) => {
    setUserProfiles(profiles);
  }, []);

  const onNavigationHandler = () => {
    setIsProfilesLoading(true);
    setIsTemporaryProfile(false);
    dispatchSelectedProfile(null);
  };

  const dispatchSelectedProfile = useCallback((selectedProfile) => {
    if (selectedProfile === null) {
      setIsFilterChanged(false);
    } else if (!selectedProfile?.key) {
      setIsFilterChanged(true);
    } else {
      setIsFilterChanged(false);
    }

    setSelectedUserProfile(selectedProfile);
  }, []);

  const contextValue = useMemo(
    () => ({
      dispatchProfiles,
      dispatchSelectedProfile,
      isFilterChanged,
      isProfilesLoading,
      isTemporaryProfile,
      onNavigationHandler,
      selectedUserProfile,
      setFilerChange,
      setFilters,
      setIsFilterChanged,
      setIsProfilesLoading,
      setIsTemporaryProfile,
      userProfiles,
      setMultipleFilters,
    }),
    [
      dispatchProfiles,
      dispatchSelectedProfile,
      isFilterChanged,
      isProfilesLoading,
      isTemporaryProfile,
      onNavigationHandler,
      selectedUserProfile,
      setFilerChange,
      setFilters,
      setIsFilterChanged,
      setIsProfilesLoading,
      setIsTemporaryProfile,
      userProfiles,
      setMultipleFilters,
    ],
  );

  return <UserProfile.Provider value={contextValue}>{children}</UserProfile.Provider>;
};

UserProfileProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserProfileProvider;
